<script setup lang="ts">
import type {
  Filter,
  FilterCategoryName,
  NestedFilter,
} from '@/types/list-page';

const showNestedFilter = (filter: Filter): filter is NestedFilter => isNestedFilter(filter)
  && !!filter.subItems.some((item) => !item.isDisabled);

defineProps<{
  filters: Filter[];
  groupTitle: FilterCategoryName;
}>();
</script>

<template>
  <div class="nested-filter-list flex flex-col">
    <template
      v-for="(filter, index) in filters"
      :key="index"
    >
      <div
        class="border-t border-grey-light py-3 md:border-0 md:pb-2.5 md:pt-0 md:last-of-type:pb-0"
        :class="{ 'group/filterIsDisabled text-grey': filter.isDisabled }"
      >
        <NestedFilterItem
          v-if="showNestedFilter(filter)"
          :filter="filter"
          :group-title="groupTitle"
        />
        <FilterItemSingleSelect
          v-else-if="groupTitle === 'productColor'"
          :all-filters-in-group="filters"
          :current-filter="filter"
          :group-title="groupTitle"
        />
        <FilterItem
          v-else
          is-parent-partly-selected
          :filter="filter"
          :group-title="groupTitle"
        />
      </div>
    </template>
  </div>
</template>
