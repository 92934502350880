<script setup lang="ts">
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from 'lucide-vue-next';
import { AMOUNT_FOR_SHOW_LESS_FILTERS_SUB } from '@/config';
import type {
  FilterCategoryName,
  NestedFilter,
} from '@/types/list-page';

withDefaults(defineProps<{
  filter: NestedFilter;
  groupTitle: FilterCategoryName;
}>(), {  })

const { t } = useI18n();

const isShowAllVisible = computed(() => __props.filter.subItems.length > AMOUNT_FOR_SHOW_LESS_FILTERS_SUB);

const {
  onToggleShowAll,
  showAll,
} = useShowAllOrLessFilters();

const isExpanded = ref(false);

const { toggleActiveFilters } = useActiveFilters();

const label = computed(() => (__props.filter.label ?? t(__props.groupTitle === 'product'
  ? `product.category.${__props.filter.key}`
  : `designListing.filter.${__props.groupTitle}.${__props.filter.key}`)));

const hasSelectedEntries = computed(() => __props.filter.subItems
  .some((subItem) => subItem.isSelected));

const areAllSelected = computed(() => areAllEnabledSubItemsSelected(__props.filter.subItems));

const isPartlySelected = computed(() => !areAllSelected.value && hasSelectedEntries.value);

const filterItemClasses = computed(() => ({
  'cursor-default pointer-events-none': __props.filter.isDisabled,
  'filter-item-checked': __props.filter.isSelected,
  'is-expanded': isExpanded.value,
}));

const visibleSubFilters = computed(() => (showAll.value
  ? __props.filter.subItems
  : Object.values(__props.filter.subItems).slice(0, AMOUNT_FOR_SHOW_LESS_FILTERS_SUB)));

const toggleExpandedState = () => {
  if (!__props.filter.isDisabled) {
    isExpanded.value = !isExpanded.value;
  }
};

const filterClick = () => {
  isExpanded.value = !__props.filter.isSelected;

  toggleActiveFilters({
    filterCategoryName: __props.groupTitle,
    filterKey: __props.filter.key,
    wasFilterSelected: !!__props.filter.isSelected,
  });
};

onUpdated(() => {
  if (__props.filter.isDisabled) {
    isExpanded.value = false;
  }
});
</script>

<template>
  <div>
    <div
      class="nested-filter-item group/checkbox group flex w-full cursor-pointer items-center text-sm text-dark hover:text-semidark"
      :class="filterItemClasses"
    >
      <UiCheckbox
        :id="filter.key"
        :is-checked="filter.isSelected || isPartlySelected || areAllSelected"
        :is-disabled="!!filter.isDisabled"
        :is-partly-selected="isPartlySelected"
        @click="filterClick"
      />
      <div
        class="flex w-full justify-between pl-2.5 md:justify-start"
        @click.stop="toggleExpandedState"
      >
        <span
          class="max-w-[calc(100%-10px)]"
          :class="{ 'font-medium': filter.isSelected || isPartlySelected, 'text-grey!': !!filter.isDisabled }"
        >
          {{ label }}
        </span>
        <component
          :is="isExpanded ? ChevronUpIcon : ChevronDownIcon"
          v-if="!filter.isDisabled"
          class="expand-icon ml-2 mr-[3px] hidden size-6 group-hover/checkbox:inline group-[.is-expanded]:inline md:mr-0 md:size-4 [@media(pointer:coarse)]:inline [@media(pointer:none)]:inline"
          :class="{ 'inline!': filter.isSelected || isPartlySelected }"
        />
      </div>
    </div>
    <div
      v-if="isExpanded"
      class="pb-2 pt-2.5 last-of-type:pb-0"
    >
      <template
        v-for="(subFilter, subFilterIndex) in visibleSubFilters"
        :key="subFilterIndex"
      >
        <div class="group/checkbox md:to-0 ml-[9px] flex border-l-2 border-solid border-purple-light py-2.5 pl-5 last-of-type:mb-2.5 last-of-type:pb-0 md:pt-0 md:last-of-type:mb-0">
          <FilterItem
            :filter="subFilter"
            :group-title="groupTitle"
            :is-parent-partly-selected="isPartlySelected"
          />
        </div>
      </template>
      <ShowAllOrLess
        v-if="isShowAllVisible"
        class="ml-6"
        :show-all="showAll"
        @toggle-show-all="onToggleShowAll"
      />
    </div>
  </div>
</template>
