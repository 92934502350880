<script setup lang="ts">
import { useModalVisible } from '@/composables/useModalVisible';

const { setIsModalVisible } = useModalVisible();

const {
  getCurrentFilterGroups,
  getSelectedMobileFilter,
  toggleMobileFilterOverlay,
} = useActiveFilters();

const filterOverlay = ref(null);

useIntersectionObserver(
  filterOverlay,
  ([ { isIntersecting } ]) => {
    setIsModalVisible(isIntersecting);
  },
);

onMounted(() => {
  setIsModalVisible(true);
});

onUnmounted(() => {
  setIsModalVisible(false);
});

const displayableFilterGroups = computed(() => (getSelectedMobileFilter.value
  ? getCurrentFilterGroups.value.filter((group) => group.key === getSelectedMobileFilter.value)
  : getCurrentFilterGroups.value));
</script>

<template>
  <div
    ref="filterOverlay"
    class="filter-groups-overlay fixed left-0 top-0 z-[80] size-full bg-grey-light md:hidden"
    data-fs="filters-list"
  >
    <div class="filter-groups-overlay-content h-full overflow-scroll rounded-lg rounded-b-none">
      <FilterHeader class="filter-groups-overlay-headline border-b border-grey-mid p-3.5" />
      <div class="p-3.5 pb-[70px]">
        <FilterGroup
          v-for="(filterGroup) in displayableFilterGroups"
          :key="filterGroup.key"
          class="rounded-lg bg-white"
          :filter-group="filterGroup"
          :is-single-view="getSelectedMobileFilter !== null"
        />
      </div>
      <div class="fixed bottom-0 flex w-full justify-between border-t border-grey-mid bg-white p-3.5">
        <SelectedFilterBarResetButton class="mr-3.5 h-10 rounded-lg" />
        <UiButtonLink
          class="filter-groups-overlay-apply-filters h-10 grow"
          :text="$t('designListing.applyFilters')"
          @click="toggleMobileFilterOverlay()"
        />
      </div>
    </div>
  </div>
</template>
