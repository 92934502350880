<script setup lang="ts">
import {
  SlidersHorizontal,
  XIcon,
} from 'lucide-vue-next';

const { toggleMobileFilterOverlay } = useActiveFilters();
</script>

<template>
  <header class="filter-header flex items-center justify-start bg-white text-base font-medium">
    <SlidersHorizontal class="mr-2 size-5" />
    <p class="m-0 text-xl font-bold">
      {{ $t('designListing.filters') }}
    </p>
    <button
      class="close-button ml-auto flex size-10 cursor-pointer items-center justify-center rounded-full bg-grey-light text-dark hover:bg-dark hover:text-white active:bg-dark active:text-white"
      type="button"
      @click="toggleMobileFilterOverlay()"
    >
      <XIcon />
    </button>
  </header>
</template>
