<script setup lang="ts">
import { SlidersHorizontal } from 'lucide-vue-next';

const {
  getIsFiltersListHidden,
  setIsFiltersListHidden,
} = useActiveFilters();

const filterVisibilityToUpdate = computed(() => getIsFiltersListHidden.value);
</script>

<template>
  <Button
    class="filter-button mr-4 w-full rounded-3xl"
    type="button"
    @click="setIsFiltersListHidden(!filterVisibilityToUpdate)"
  >
    <SlidersHorizontal class="size-4" />
    <span>{{ $t(getIsFiltersListHidden ? 'designListing.showFilters' : 'designListing.hideFilters') }}</span>
  </Button>
</template>
