<script setup lang="ts">
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from 'lucide-vue-next';

interface Props {
  filterGroupKey: string;
  isExpandable?: boolean;
  isExpanded: boolean;
}

const { t } = useI18n();

withDefaults(defineProps<Props>(), { isExpandable: false, })

const label = t(`designListing.filter.${__props.filterGroupKey}.title`);

defineEmits<{
  toggleExpanded: [];
}>();
</script>

<template>
  <div
    class="filter-group-headline flex cursor-pointer items-center justify-between md:my-5"
    data-test="filter-group-headline"
    :class="{ 'pb-1.5 md:pb-0': isExpanded }"
    @click="isExpandable && $emit('toggleExpanded')"
  >
    <div class="flex">
      <span class="text-base font-bold md:font-medium">{{ label }}</span>
    </div>
    <div
      class="flex size-8 items-center justify-center rounded-full bg-grey-light md:size-4 md:bg-white"
      :class="{ 'invisible': !isExpandable }"
    >
      <component
        :is="isExpanded ? ChevronUpIcon : ChevronDownIcon"
        class="self-center"
      />
    </div>
  </div>
</template>
