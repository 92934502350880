<script setup lang="ts">
import type {
  FilterCategoryName,
  FilterCategoryWithFacets,
} from '@/types/list-page';

const defaultSmartFilters = [
  'product',
  'designType',
  'designColor',
];

const specificSmartFilters = {
  orientation: [
    'acrylglas',
    'acrylglas-gerahmt',
    'aludibond',
    'aludibond-gerahmt',
    'backlitfolie',
    'forexplatte',
    'forexplatte-gerahmt',
    'fototapete',
    'hahnemuehle',
    'hahnemuehle-alurahmen',
    'hahnemuehle-holzrahmen',
    'hahnemuehle-kunststoffrahmen',
    'leinwand',
    'leinwand-schattenfuge',
    'poster',
    'poster-alurahmen',
    'poster-holzrahmen',
    'poster-kunststoffrahmen',
    'pvcplane',
    // This leads to an incorrect filter
    // 'sticker',
  ],
  productColor: [
    'bib-baby',
    'hoodie',
    'hoodie-kid',
    'romper-long-sleeve-baby',
    'romper-short-sleeve-baby',
    'sweatshirt',
    'sweatshirt-baby',
    'sweatshirt-kid',
    't-shirt',
    't-shirt-baby',
    't-shirt-fitted-woman',
    't-shirt-kid',
  ],
};

const {
  getActiveFilters,
  getActiveProductTypes,
} = useActiveFilters();

const relevantFilters = computed(() => Object.entries(getActiveFilters.value).reduce((acc, [
  key,
  value,
]) => {
  if (value.length) {
    acc[key as FilterCategoryName] = [ ...new Set(value) ];
  }
  return acc;
}, {} as FilterCategoryWithFacets));

const getPredefinedSmartFilters = computed(() => {
  const activeProduct = getActiveProductTypes.value?.[0];
  const specificFilterKey = activeProduct
    ? Object.entries(specificSmartFilters).find(([
      , products,
    ]) => products.includes(activeProduct))?.[0]
    : '';

  const mergedFilters = [
    ...defaultSmartFilters,
    ...(specificFilterKey
      ? [ specificFilterKey ]
      : []),
  ];

  return mergedFilters.filter((filter) => !Object.hasOwn(relevantFilters.value, filter));
});
</script>

<template>
  <div class="mobile-selected-filter-bar flex items-center bg-white">
    <UiScroller
      fade-out-edges
      has-full-height-buttons
      hide-arrows
      never-hide-button
      background-color="white"
    >
      <template #scrollerContent>
        <MobileFilterButton />
        <div class="selected-filter-bar-wrapper flex flex-nowrap justify-start">
          <SelectedFilterPill
            v-for="[activeFilterKey, activeFilters] in Object.entries(relevantFilters)"
            :key="activeFilterKey"
            :count="activeFilters.length"
            :filter-key="activeFilterKey as FilterCategoryName"
          />
          <SelectedFilterPill
            v-for="filter in getPredefinedSmartFilters"
            :key="filter"
            :filter-key="filter as FilterCategoryName"
          />
        </div>
      </template>
    </UiScroller>
  </div>
</template>
