<script setup lang="ts">
import { SEARCH_PARAM } from '@/config/filter';
import type {
  FilterCategoryName,
  FilterCategoryWithFacets,
} from '@/types/list-page';

const { isDesktop } = useDevice();

const { getActiveFilters } = useActiveFilters();

withDefaults(defineProps<{
  hideResetButton?: boolean;
  includeFilterButton?: boolean;
}>(), { hideResetButton: false,includeFilterButton: true, })

const route = useRoute();
const queryParam = computed(() => route.query.q?.toString() || null);

const getFilterTitleTranslationKey = (filterKey: string) => `designListing.filter.${filterKey}.title`;

const relevantFilters = computed(() => Object.entries(getActiveFilters.value).reduce((acc, [
  key,
  value,
]) => {
  if (value.length) {
    acc[key as FilterCategoryName] = [ ...new Set(value) ];
  }
  return acc;
}, {} as FilterCategoryWithFacets));

const hasRelevantFilters = computed(() => relevantFilters.value
    && typeof relevantFilters.value === 'object'
    && Object.keys(relevantFilters.value || {}).length > 0);
</script>

<template>
  <div
    v-if="hasRelevantFilters"
    class="selected-filter-bar flex items-center bg-white"
  >
    <UiScroller
      has-full-height-buttons
      never-hide-buttons
    >
      <template #scrollerContent>
        <MobileFilterButton
          v-if="includeFilterButton"
          class="flex md:hidden"
        />
        <div class="selected-filter-bar-wrapper flex flex-nowrap justify-start">
          <PseudoFilter
            translation-key="common.search"
            :filter="queryParam"
            :group-title="SEARCH_PARAM"
          />
          <div
            v-for="[activeFilterKey, activeFilters] in Object.entries(relevantFilters)"
            :key="activeFilterKey"
            class="filter-group md:mr-5"
          >
            <span class="filter-group-headline mb-1 mt-0 hidden px-2 text-xxs font-medium text-grey-dark md:block">
              {{ $t(getFilterTitleTranslationKey(activeFilterKey)) }}
            </span>
            <div class="filter-group-items flex">
              <SelectedFilterBarItem
                v-for="filter in activeFilters"
                :key="filter"
                :filter-key="filter"
                :group-title="(activeFilterKey as FilterCategoryName)"
              />
            </div>
          </div>
        </div>
      </template>
    </UiScroller>
    <SelectedFilterBarResetButton
      v-if="!hideResetButton"
      class="h-[30px]"
      :show-label="isDesktop"
    />
  </div>
  <div v-else-if="includeFilterButton">
    <MobileFilterButton class="flex md:hidden" />
  </div>
</template>
