<script setup lang="ts">
import { ChevronDownIcon } from 'lucide-vue-next';

import type { FilterCategoryName } from '@/types/list-page';

withDefaults(defineProps<{
  count?: number;
  filterKey: FilterCategoryName;
}>(), { count: 0, })

const { toggleMobileFilterOverlay } = useActiveFilters();
</script>

<template>
  <button
    class="mr-1.5 flex flex-nowrap items-center whitespace-nowrap rounded-2xl border border-grey-mid py-1.5 pl-3.5 text-xs font-semibold"
    :class="[{ 'border-purple-hazy bg-purple-extra-light': count }, count ? 'pr-1.5' : 'pr-3']"
    @click="toggleMobileFilterOverlay(filterKey)"
  >
    {{ $t(`designListing.filter.${filterKey}.title`) }}
    <ChevronDownIcon class="ml-0.5 h-4 w-3" />
    <span
      v-if="count"
      class="ml-0.5 flex size-4 items-center justify-center rounded-full border-purple bg-purple text-white"
    >
      <span>{{ count }}</span>
    </span>
  </button>
</template>
