<script setup lang="ts">
import type {
  BaseFilter,
  FilterCategoryName,
} from '@/types/list-page';

interface Props {
  filter: BaseFilter;
  groupTitle: FilterCategoryName;
}

withDefaults(defineProps<Props>(), {  })
const { toggleActiveFilters } = useActiveFilters();
</script>

<template>
  <div class="color-filter-item flex justify-center">
    <div
      :class="[ 'ring:offset-transparent size-6 shrink-0 cursor-pointer rounded-full border border-grey-mid ring-offset-4',
                filter.isSelected ? 'ring-2 ring-purple' : 'hover:ring-1 hover:ring-purple' ]"
      :style="{ backgroundColor: `#${filter.key}` }"
      @click="toggleActiveFilters({ filterCategoryName: groupTitle, filterKey: filter.key, wasFilterSelected: !!filter.isSelected })"
    />
  </div>
</template>
