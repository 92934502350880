<script setup lang="ts">
import { FEATURE_FLAG_NAME } from '@/config/ab-tests';

withDefaults(defineProps<{
  numberOfFilterResults: number;
}>(), {  })

const { n } = useI18n();
const { isTreatmentOn } = useSplit();

const numberOfHits = computed(() => n(__props.numberOfFilterResults));
</script>

<template>
  <div class="design-type-filters flex items-center justify-between">
    <div class="whitespace-nowrap text-xs uppercase md:block md:w-[100px] md:text-sm md:normal-case">
      {{ $t('designListing.numberOfDesigns', { number: numberOfHits }) }}
    </div>
    <SortingSelection v-if="!isTreatmentOn(FEATURE_FLAG_NAME.JQ_4152_REMOVE_FILTER_BAR)" />
  </div>
</template>
