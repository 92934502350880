<script setup lang="ts">
import { isEqual } from 'lodash-es';

const {
  getAreInitialFiltersSetViaSearchParams,
  getPreselectedFilters,
} = usePreselectedFilters();

const {
  getActiveFilters,
  getSelectedMobileFilter,
  setActiveFilters,
  updateCurrentFilterGroupsAfterFilterWasToggled,
  updateSearchParams,
} = useActiveFilters();

interface Props {
  showLabel?: boolean;
}

withDefaults(defineProps<Props>(), { showLabel: true, })

const isResetDisabled = computed(() => isEqual(getPreselectedFilters.value, getActiveFilters.value));

const resetFilter = () => {
  if (getSelectedMobileFilter.value) {
    const preselectedFilters = getPreselectedFilters.value;
    const currentFilters = getActiveFilters.value;
    currentFilters[getSelectedMobileFilter.value] = preselectedFilters[getSelectedMobileFilter.value];
    setActiveFilters(currentFilters);
    updateCurrentFilterGroupsAfterFilterWasToggled();
    updateSearchParams(false);
  } else {
    setActiveFilters(getPreselectedFilters.value);
    updateCurrentFilterGroupsAfterFilterWasToggled();
    updateSearchParams(!getAreInitialFiltersSetViaSearchParams.value);
  }
};
</script>

<template>
  <button
    class="reset-filters solid relative flex items-center justify-center whitespace-nowrap rounded-2xl border border-solid border-grey px-2.5 text-xs md:ml-7 md:h-10 md:rounded-[20px]"
    type="button"
    :class="[isResetDisabled ? 'pointer-events-none cursor-default text-grey' : 'cursor-pointer text-dark hover:border-2 hover:border-dark hover:px-[9px]']"
    :disabled="isResetDisabled"
    @click="resetFilter"
  >
    <ResetIcon class="icon size-5" />
    <span
      v-if="showLabel"
      class="label ml-2 inline-block"
    >
      {{ getSelectedMobileFilter ? $t('designListing.resetFilters') : $t('designListing.resetAllFilters') }}
    </span>
  </button>
</template>
