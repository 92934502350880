<script setup lang="ts">
import { AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN } from '@/config/filter';
import type {
  FilterCategoryName,
  FilterGroup,
} from '@/types/list-page';

withDefaults(defineProps<{
  filterGroup: FilterGroup;
  isSingleView?: boolean;
}>(), { isSingleView: false, })

const {
  onToggleShowAll,
  showAll,
} = useShowAllOrLessFilters();

const { getRelevantFilters } = useActiveFilters();

const isExpanded = ref(__props.filterGroup.isExpanded);

const onToggleExpanded = () => {
  isExpanded.value = !isExpanded.value;
};

const colorFilters = [
  'designColor',
  'productColor',
];

const isShowAllVisible = computed(() => !colorFilters.includes(__props.filterGroup.key)
  && __props.filterGroup.filters.length > AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN);

const entries = computed(() => (showAll.value
  || colorFilters.includes(__props.filterGroup.key)
  || __props.isSingleView
  ? __props.filterGroup.filters
  : Object.values(__props.filterGroup.filters).slice(0, AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN)));

const getFilterType = computed(() => {
  switch (__props.filterGroup.key) {
    case 'designColor':
      return resolveComponent('ColorFilterList');
    case 'productColor':
      return resolveComponent('ProductColorFilter');
    default:
      return resolveComponent('FilterList');
  }
});

const selectedFilters = computed(() => (__props.filterGroup.key in getRelevantFilters.value
  ? getRelevantFilters.value[__props.filterGroup.key]
  : []));
</script>

<template>
  <div
    v-if="!filterGroup.isHidden"
    class="filter-group mb-2.5 w-full px-3 pb-1.5 pt-2 md:my-5 md:p-0"
    :class="{ expanded: isExpanded }"
    :data-fs="`filters-${filterGroup.key}`"
  >
    <FilterGroupHeadline
      :filter-group-key="filterGroup.key"
      :is-expandable="!isSingleView"
      :is-expanded="isExpanded || false"
      @toggle-expanded="onToggleExpanded"
    />
    <div
      v-if="isExpanded"
      data-test="expandable-filters"
    >
      <component
        :is="getFilterType"
        :filters="entries"
        :group-title="filterGroup.key"
      />
      <ShowAllOrLess
        v-if="isShowAllVisible && !isSingleView"
        class="border-t border-grey-light pb-1.5 pt-3 md:mt-5 md:border-0 md:py-0"
        :show-all="showAll"
        @toggle-show-all="onToggleShowAll"
      />
    </div>
    <div
      v-else-if="!isExpanded && selectedFilters.length"
      class="relative mb-2 mt-0 flex w-[calc(100%-32px)] overflow-hidden truncate md:hidden"
    >
      <SelectedFilterBarItem
        v-for="(filter, index) in selectedFilters"
        :key="filter"
        :filter-key="filter"
        :group-title="(filterGroup.key as FilterCategoryName)"
        :is-button="false"
        :is-last-item="index === selectedFilters.length - 1"
      />
      <span class=" absolute right-0 top-0 block h-full w-10 bg-linear-to-r from-transparent to-white"></span>
    </div>
  </div>
</template>
