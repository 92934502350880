<script setup lang="ts">
import { InfoIcon } from 'lucide-vue-next';
import { colors } from 'shared-components';

defineProps<{
  searchQuery?: string;
}>();

const FORMATTED_PHONE_NUMBER = '+49 (0) 30 22385614';
const PHONE_NUMBER = FORMATTED_PHONE_NUMBER.replace(/\(0\)/, '').replace(/\s/g, '');

const tips = computed(() => [
  'search.noResults.tips.checkSpelling',
  'search.noResults.tips.resetFilters',
  'search.noResults.tips.genericSearchTerm',
  'search.noResults.tips.help',
]);
</script>

<template>
  <div class="container mt-4 rounded-lg border border-grey-mid bg-grey-extra-light py-4 md:py-5 lg:py-10">
    <h2 class="my-0 text-2xl font-bold">
      {{ $t('search.noResults.headline') }}
    </h2>
    <p class="mt-1 text-base">
      {{ $t('search.noResults.subline') }}
    </p>
    <div class="mt-6 grid max-w-2xl grid-cols-1 gap-4 md:grid-cols-2">
      <div
        v-for="tip in tips"
        :key="tip"
        class="flex items-start gap-1.5"
      >
        <InfoIcon class="size-4 shrink-0 text-grey-semidark/50" />
        <i18n-t
          class="block text-sm leading-tight text-grey-semidark"
          scope="global"
          tag="span"
          :keypath="tip"
        >
          {{ tip }}
          <template #phoneNumber>
            <a
              class="inline-block text-purple"
              :href="`tel:${PHONE_NUMBER}`"
            >
              {{ FORMATTED_PHONE_NUMBER }}
            </a>
          </template>
        </i18n-t>
      </div>
    </div>
    <div class="mt-8 border-t border-grey-mid pt-4">
      <ProductCarousel
        full-width
        :blok="{
          _uid: 'no-search-results',
          containerSize: 'large',
          component: 'ProductCarousel',
          preselectedSorting: 'bestseller',
          backgroundColor: { value: colors['grey-extra-light']},
          headline: $t('search.noResults.getInspired.headline'),
          subline: $t('search.noResults.getInspired.subline'),
          spacingTop: 'none',
          spacingBottom: 'none',
          expandable: true
        }"
      />
    </div>
  </div>
</template>
