<script setup lang="ts">
import { CheckIcon } from 'lucide-vue-next';

withDefaults(defineProps<{
  checkMarkColor?: string;
  id: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isPartlySelected?: boolean;
  rounded?: 'medium' | 'full';
}>(), { rounded: 'full', })

const backgroundClasses = computed(() => ({
  'bg-purple-dark border-purple-dark hover:border-purple-dark hover:bg-purple-dark group-hover/checkbox:border-purple-dark group-hover/checkbox:bg-purple-dark': !__props.isPartlySelected && __props.isChecked,
  'bg-white border-grey': (!__props.isPartlySelected && !__props.isChecked) || __props.isDisabled,
  'bg-white border-purple-dark': (__props.isPartlySelected && __props.isChecked),
  'cursor-default!': __props.isDisabled,
}));

const checkmarkClasses = computed(() => (__props.checkMarkColor
  ? [
    __props.isChecked
      ? `text-${__props.checkMarkColor}`
      : `opacity-0 group-hover/checkbox:opacity-60 group-hover/checkbox:text-${__props.checkMarkColor}`,
  ]
  : {
    'text-purple-dark group-hover/checkbox:text-purple-dark': __props.isPartlySelected && __props.isChecked,
    'text-white': !__props.isPartlySelected && __props.isChecked,
    'text-white group-hover/checkbox:text-grey': !__props.isPartlySelected && !__props.isChecked,
    'text-white group-hover/checkbox:text-white cursor-default': __props.isDisabled,
  }
));
</script>

<template>
  <div
    :id="id"
    class="ui-checkbox relative flex size-5 flex-none cursor-pointer items-center justify-center border-[1.5px] border-solid"
    :class="[backgroundClasses, rounded === 'medium' ? 'rounded-md' : 'rounded-full']"
  >
    <CheckIcon
      class="absolute size-3"
      :class="checkmarkClasses"
    />
  </div>
</template>
