<script setup lang="ts">
import { FEATURE_FLAG_NAME } from '@/config/ab-tests';
import { HITS_PER_PAGE } from '@/config/designs';
import type { DesignsQuery } from '#gql';

const ANIMATION_DELAY = 1000;
const MAX_PAGES_SHOWN_IN_PAGINATION = 3;
const MAX_RESULTS_TO_GET = 1000;
const MINIMUM_AMOUNT_OF_DESIGNS = 2;

withDefaults(defineProps<{
  designs: DesignsQuery['designs']['nodes'] | null;
  totalCount: number;
}>(), {  })

const {
  getActiveFilters,
  getActiveProductTypes,
  getCurrentFilterGroups,
  getIsFiltersListHidden,
} = useActiveFilters();

const { isTreatmentOn } = useSplit();

const isAnimationDisabled = ref(true);

const animationClasses = computed(() => ({
  'animation-disabled': isAnimationDisabled.value,
  'hide-filter': getIsFiltersListHidden.value,
}));

const visibleFilterGroups = computed(() => {
  const filterGroups = getCurrentFilterGroups.value;

  filterGroups.forEach((group) => {
    group.filters.forEach((filter) => {
      if (__props.totalCount === 0) {
        const updatedFilter = { ...filter };
        updatedFilter.isDisabled = true;
        group.filters.splice(group.filters.indexOf(filter), 1, updatedFilter);
      }
    });
  });

  return filterGroups;
});

const totalPages = computed(() => Math.ceil(__props.totalCount / HITS_PER_PAGE - 1));

const getMaxDesignsToGet = computed(() => (__props.totalCount > MAX_RESULTS_TO_GET
  ? MAX_RESULTS_TO_GET
  : __props.totalCount));

onMounted(() => {
  setTimeout(() => {
    // do not use keyframe animation on initial pageload
    isAnimationDisabled.value = false;
  }, ANIMATION_DELAY);
});

const searchQuery = useRoute().query.q as string ?? '';
</script>

<template>
  <div
    class="results-container flex"
    data-cy="results-container"
    :data-fs="totalCount < MINIMUM_AMOUNT_OF_DESIGNS ? 'result-list-empty' : ''"
  >
    <div
      v-if="!isTreatmentOn(FEATURE_FLAG_NAME.JQ_4152_REMOVE_FILTER_BAR)"
      class="filter-groups-sidebar-wrapper sticky top-20 z-40 hidden overflow-auto border-r border-solid border-grey-mid bg-white bg-fixed md:block md:px-5 md:pb-5 md:pt-0 lg:pl-10 lg:pr-5"
      data-fs="filters-list"
      :class="animationClasses"
    >
      <FilterGroup
        v-for="(filterGroup) in visibleFilterGroups"
        :key="filterGroup.key"
        :filter-group="filterGroup"
      />
    </div>
    <div
      v-if="designs"
      class="result-list w-full"
    >
      <div
        class="relative bg-linear-to-b from-grey-extra-light to-white px-4 pt-2 md:px-5 md:pb-0.5 md:pt-4 lg:py-2 lg:pl-5 lg:pr-10 2xl:pr-[calc(((100vw-1710px)/2)+40px)]!"
        :class="{ 'lg:pl-10! 2xl:pl-[calc(((100vw-1710px)/2)+40px)]!' : getIsFiltersListHidden || isTreatmentOn(FEATURE_FLAG_NAME.JQ_4152_REMOVE_FILTER_BAR) }"
      >
        <ResultsContainerHeader
          v-if="totalCount > 0"
          :number-of-filter-results="totalCount"
        />
        <NoResults
          v-if="totalCount < 1"
          :search-query="searchQuery"
        />
      </div>
      <div
        class="design-list p-4 md:px-5 md:py-0 lg:py-0 lg:pl-5 lg:pr-10 2xl:pr-[calc(((100vw-1710px)/2)+40px)]!"
        :class="[
          { getIsFiltersListHidden, 'md:mb-8': totalPages < 1 },
          { 'lg:pl-10! 2xl:pl-[calc(((100vw-1710px)/2)+40px)]!': getIsFiltersListHidden || isTreatmentOn(FEATURE_FLAG_NAME.JQ_4152_REMOVE_FILTER_BAR) },
        ]"
      >
        <div
          class="designs-list grid w-full items-start gap-x-2.5 gap-y-5"
          :class="[
            getIsFiltersListHidden || isTreatmentOn(FEATURE_FLAG_NAME.JQ_4152_REMOVE_FILTER_BAR) ? 'grid-cols-2 sm:grid-cols-3 ml:grid-cols-4 2xl:grid-cols-5' : 'grid-cols-2 ml:grid-cols-3 lg:grid-cols-4',
          ]"
        >
          <DesignTile
            v-for="design in designs"
            :key="design.objectId"
            show-product-type
            :active-filters="getActiveFilters"
            :active-product-types="getActiveProductTypes ?? []"
            :design="design"
          />
        </div>
        <UiPagination
          v-if="totalPages > 0"
          class="mt-5 px-4 py-5 md:p-5 lg:py-5 lg:pl-5 lg:pr-10 2xl:pr-[calc(((100vw-1710px)/2)+40px)]!"
          :class="{ 'lg:pl-10! 2xl:pl-[calc(((100vw-1710px)/2)+40px)]!': getIsFiltersListHidden }"
          :items-per-page="HITS_PER_PAGE"
          :max-pages-shown="MAX_PAGES_SHOWN_IN_PAGINATION"
          :total-items="getMaxDesignsToGet"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 768px) {
  .results-container {
    grid-area: results;
  }

  .filter-groups-sidebar-wrapper {
    flex: 0 0 290px;
    max-width: 290px;
    height: calc(100vh - 80px);
    animation: filter-slide-in 200ms forwards;
  }

  .filter-groups-sidebar-wrapper.hide-filter {
    animation: filter-slide-out 200ms forwards;
  }

  .animation-disabled {
    animation-duration: 0s !important;
  }
}

@media (min-width: 1700px) {
  .filter-groups-sidebar-wrapper {
    flex: 0 0 calc(290px + calc(((100vw - 1710px) / 2) + 40px));
    max-width: unset;
    padding-left: calc(((100vw - 1710px) / 2) + 40px);
    animation: filter-slide-in-big 200ms forwards;
  }

  .filter-groups-sidebar-wrapper.hide-filter {
    animation: filter-slide-out-big 200ms forwards;
  }

  @keyframes filter-slide-in-big {
    0% {
      margin-left: calc((290px + calc(((100vw - 1710px) / 2) + 40px)) * -1);
      visibility: visible;
    }

    100% {
      margin-left: 0;
    }
  }

  @keyframes filter-slide-out-big {
    0% {
      margin-left: 0;
    }

    100% {
      margin-left: calc((290px + calc(((100vw - 1710px) / 2) + 40px)) * -1);
      visibility: hidden;
    }
  }
}

@keyframes filter-slide-in {
  0% {
    margin-left: -290px;
    visibility: visible;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes filter-slide-out {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -290px;
    visibility: hidden;
  }
}
</style>
