<script setup lang="ts">
import { SlidersHorizontal } from 'lucide-vue-next';

const { toggleMobileFilterOverlay } = useActiveFilters();
</script>

<template>
  <button
    class="mr-1.5 flex rounded-2xl border border-grey-mid px-3.5 py-1.5 text-xs font-bold"
    @click="toggleMobileFilterOverlay('default')"
  >
    <SlidersHorizontal class="size-4" /> <span class="ml-1.5">{{ $t('designListing.filters') }}</span>
  </button>
</template>
